import clsx from "clsx";
import type { FC } from "react";
import React, { memo } from "react";

import colors from "../styles/colors.module.scss";
import styles from "./waves.module.scss";

export type Props = {
  wrapperClassName?: string;
  className?: string;
  inverted?: boolean;
};

const Waves: FC<Props> = (props): JSX.Element => {
  const { className, inverted } = props;

  let colorOne = colors.white;
  let colorTwo = colors.cyan;
  let colorThree = colors.lightBlue;
  let colorFour = colors.darkBlue;

  if (inverted) {
    colorOne = colors.lightBlue;
    colorTwo = colors.cyan;
    colorThree = colors.darkBlue;
    colorFour = colors.white;
  }

  return (
    <svg
      className={clsx(styles.container, className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 24 150 28"
      preserveAspectRatio="none"
      role="img"
      aria-label="Animating waves dividing two sections."
    >
      <defs>
        <path
          id="gentle-wave"
          d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
        />
      </defs>
      <g className={styles.waveOne}>
        <use xlinkHref="#gentle-wave" x="48" y="3" fill={colorFour} />
      </g>
      <g className={styles.waveTwo}>
        <use xlinkHref="#gentle-wave" x="48" y="0" fill={colorThree} />
      </g>
      <g className={styles.waveThree}>
        <use xlinkHref="#gentle-wave" x="48" y="9" fill={colorTwo} />
      </g>
      <g className={styles.waveFour}>
        <use xlinkHref="#gentle-wave" x="48" y="6" fill={colorOne} />
      </g>
    </svg>
  );
};

Waves.displayName = "Waves";
Waves.defaultProps = {
  inverted: false,
};

export default memo(Waves);
