import clsx from "clsx";
import type { ImageProps } from "next/future/image";
import Image from "next/future/image";
import type { FC } from "react";
import React, { memo } from "react";

import styles from "./card.module.scss";

export type Props = ImageProps & {
  className?: string;
  alt: string;
  title: string;
  description: string;
};

const Card: FC<Props> = (props): JSX.Element => {
  const { className, title, description, ...otherProps } = props;

  return (
    <section className={clsx(styles.container, className)}>
      <div className={styles.imageWrapper}>
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <Image className={styles.image} {...otherProps} />
      </div>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.description}>{description}</p>
    </section>
  );
};

Card.displayName = "Card";
Card.defaultProps = {};

export default memo(Card);
