import { baseCanonical } from "../config/seo";
import {
  getLocalizedPath,
  GetLocalizedPathOptions,
} from "../utils/get-localized-path";
import { NextSeoProps } from "next-seo";

// XXX: Extend this when new languages are added.
const LOCALES = ["en", "nl"];

export type UseLanguageAlternatesOptions = {
  paths: GetLocalizedPathOptions["paths"];
};

export type LanguageAlternatesData = {
  languageAlternates: NextSeoProps["languageAlternates"];
};

export function useLanguageAlternates(
  options: UseLanguageAlternatesOptions
): LanguageAlternatesData {
  const { paths } = options;

  const defaultLocalizedPath = getLocalizedPath({
    locale: "en",
    paths,
  });

  const languageAlternates = [
    ...LOCALES.map((locale) => {
      const localizedPath = getLocalizedPath({
        locale,
        paths,
      });

      return {
        hrefLang: locale,
        href: `${baseCanonical}${localizedPath}`,
      };
    }),
    {
      hrefLang: "x-default",
      href: `${baseCanonical}${defaultLocalizedPath}`,
    },
  ];

  return {
    languageAlternates,
  };
}
