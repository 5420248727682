import clsx from "clsx";
import type { FC, PropsWithChildren } from "react";
import React from "react";

import Waves from "./waves";

import styles from "./section.module.scss";

export type Props = PropsWithChildren<{
  wrapperClassName?: string;
  className?: string;
  wave?: boolean;
  invertedWave?: boolean;
}>;

const Section: FC<Props> = (props): JSX.Element => {
  const { wrapperClassName, className, wave, invertedWave, children } = props;

  return (
    <div
      className={clsx(
        {
          [styles.wrapper]: true,
          [styles.wrapperWithWave]: wave || invertedWave,
        },
        wrapperClassName
      )}
    >
      <section className={clsx(styles.container, className)}>
        {children}
      </section>
      {(wave || invertedWave) && <Waves inverted={invertedWave} />}
    </div>
  );
};

Section.displayName = "Section";
Section.defaultProps = {
  wave: false,
  invertedWave: false,
};

export default Section;
