import type { GetStaticPropsContext, NextPage } from "next";
import Image from "next/future/image";
import { useTranslations } from "next-intl";
import { FAQPageJsonLd, NextSeo, SocialProfileJsonLd } from "next-seo";
import { useEffect, useState } from "react";

import logoWithTextWhite from "../../public/brand/logo-with-text-white.svg";
import blockFactoryIllustration from "../../public/illustrations/block-factory.svg";
import carbonNeutralIllustration from "../../public/illustrations/carbon-neutral.svg";
import reliableIllustration from "../../public/illustrations/reliable.svg";
import secureIllustration from "../../public/illustrations/secure.svg";
import worldMapIllustration from "../../public/illustrations/world-map.png";
import instagramIllustration from "../../public/social/instagram.svg";
import twitterIllustration from "../../public/social/twitter.svg";
import adapoolsIllustration from "../../public/social/adapools.svg";

import { baseCanonical } from "../config/seo";
import { throttle } from "../utils/throttle";
import type { StatsData } from "../data/get-stats-from-api";
import { getStatsFromApi } from "../data/get-stats-from-api";
import { useCanonical } from "../hooks/use-canonical";
import { useLanguageAlternates } from "../hooks/use-language-alternates";
import Section from "../components/section";
import Title from "../components/title";
import Stats from "../components/stats";
import Button from "../components/button";
import ButtonLink from "../components/button-link";
import Card from "../components/card";
import FAQItem from "../components/faq-item";
import Flags from "../components/flags";
import Waves from "../components/waves";
import Modal from "../components/modal";

import styles from "./index.module.scss";
import { SWRConfig } from "swr";

export type Props = {
  fallback: {
    stats: StatsData;
  };
};

const Home: NextPage<Props> = ({ fallback }) => {
  const t = useTranslations();

  const paths = {
    en: "",
  };
  const { canonical } = useCanonical({
    paths,
  });
  const { languageAlternates } = useLanguageAlternates({
    paths,
  });

  useEffect(() => {
    const handleScrollChange = () => {
      const currentScrolledPosition = window.scrollY || window.pageYOffset;

      const maybeHeader = document.querySelector(`.${styles.header}`);

      if (!maybeHeader) {
        return;
      }

      if (currentScrolledPosition > 0) {
        maybeHeader.classList.add(styles.headerWithShadow);
      } else {
        maybeHeader.classList.remove(styles.headerWithShadow);
      }
    };

    const onScrollChange = () => {
      throttle(handleScrollChange, 250);
    };

    window.addEventListener("scroll", onScrollChange);

    return () => window.removeEventListener("scroll", onScrollChange);
  });

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleModalClick = () => setModalIsOpen((s) => !s);

  return (
    <SWRConfig value={{ fallback }}>
      <NextSeo
        title={t("Metadata.title")}
        description={t("Metadata.description")}
        canonical={canonical}
        languageAlternates={languageAlternates}
        openGraph={{
          type: "website",
          url: canonical,
          title: t("Metadata.title"),
          description: t("Metadata.description"),
        }}
      />
      <SocialProfileJsonLd
        type="Organization"
        name="CryptoPool.dev"
        url={baseCanonical}
        sameAs={[
          "https://instagram.com/cryptopool.dev",
          "https://twitter.com/cryptopoold",
        ]}
      />
      <FAQPageJsonLd
        mainEntity={[
          {
            questionName: t("FAQ.questionOne"),
            acceptedAnswerText: t("FAQ.answerOne"),
          },
          {
            questionName: t("FAQ.questionTwo"),
            acceptedAnswerText: t("FAQ.answerTwo"),
          },
          {
            questionName: t("FAQ.questionThree"),
            acceptedAnswerText: t("FAQ.answerThree"),
          },
          {
            questionName: t("FAQ.questionFour"),
            acceptedAnswerText: t("FAQ.answerFour"),
          },
        ]}
      />

      <header className={styles.header}>
        <div className={styles.headerContainer}>
          <Image
            className={styles.headerLogo}
            src={logoWithTextWhite}
            alt={t("Header.illustrationAlt")}
          />
          <nav>
            <Button text={t("Header.cta")} onClick={toggleModalClick} />
          </nav>
        </div>
      </header>

      <main>
        <Section
          wrapperClassName={styles.heroWrapper}
          className={styles.hero}
          wave
        >
          <div className={styles.heroContent}>
            <Title
              textFat={t("Hero.titleLineOne")}
              textThin={t("Hero.titleLineTwo")}
              h1
              monotone
              newLine
            />
            <p className={styles.heroDescription}>{t("Hero.description")}</p>
            <Stats
              className={styles.heroStats}
              epochFeeAmt={340}
              marginFeePct={5}
              returnOfStakePct={5}
            />
            <Button
              className={styles.ctaButton}
              text={t("Hero.cta")}
              onClick={toggleModalClick}
            />
          </div>
          <div className={styles.blockFactory}>
            <Image
              className={styles.illustration}
              src={blockFactoryIllustration}
              alt={t("Hero.illustrationAlt")}
              loading="eager"
            />
          </div>
        </Section>

        <Section className={styles.offering} invertedWave>
          <Title
            textFat={t("Offering.titleLineOne")}
            textThin={t("Offering.titleLineTwo")}
          />
          <div className={styles.offeringCards}>
            <Card
              src={carbonNeutralIllustration}
              alt={t("Offering.itemOneAlt")}
              title={t("Offering.itemOneTitle")}
              description={t("Offering.itemOneDescription")}
            />
            <Card
              src={reliableIllustration}
              alt={t("Offering.itemTwoAlt")}
              title={t("Offering.itemTwoTitle")}
              description={t("Offering.itemTwoDescription")}
            />
            <Card
              src={secureIllustration}
              alt={t("Offering.itemThreeAlt")}
              title={t("Offering.itemThreeTitle")}
              description={t("Offering.itemThreeDescription")}
            />
          </div>
        </Section>

        <Section
          wrapperClassName={styles.missionWrapper}
          className={styles.mission}
          wave
        >
          <Title
            textFat={t("Mission.titleLineOne")}
            textThin={t("Mission.titleLineTwo")}
            monotone
          />
          <div className={styles.missionContent}>
            <div className={styles.missionText}>
              <p className={styles.missionDescription}>
                {t("Mission.description")}
              </p>
              <ButtonLink
                className={styles.ctaButton}
                text={t("Mission.cta")}
                href="https://roadmap.cardano.org/"
              />
            </div>
            <div className={styles.worldMap}>
              <Image
                className={styles.illustration}
                src={worldMapIllustration}
                alt={t("Mission.illustrationAlt")}
                width={593}
                height={381}
                sizes="(max-width: 960px) calc(100vw - 48px), 593px"
              />
            </div>
          </div>
        </Section>

        <Section wrapperClassName={styles.faqWrapper} className={styles.faq}>
          <Title
            textFat={t("FAQ.titleLineOne")}
            textThin={t("FAQ.titleLineTwo")}
          />
          <FAQItem
            className={styles.faqItem}
            question={t("FAQ.questionOne")}
            answer={t("FAQ.answerOne")}
          />
          <FAQItem
            className={styles.faqItem}
            question={t("FAQ.questionTwo")}
            answer={t("FAQ.answerTwo")}
          />
          <FAQItem
            className={styles.faqItem}
            question={t("FAQ.questionThree")}
            answer={t("FAQ.answerThree")}
          />
          <FAQItem
            className={styles.faqItem}
            question={t("FAQ.questionFour")}
            answer={t("FAQ.answerFour")}
          />
          <Waves inverted />
        </Section>
      </main>

      <footer className={styles.footer}>
        <div className={styles.footerContainer}>
          <Image
            className={styles.footerLogo}
            src={logoWithTextWhite}
            alt={t("Footer.logoAlt")}
          />
          <div className={styles.footerSocial}>
            <a
              href="https://instagram.com/cryptopool.dev"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                className={styles.footerSocialIcon}
                src={instagramIllustration}
                alt={t("Footer.instagramAlt")}
              />
            </a>
            <a
              href="https://twitter.com/cryptopoold"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                className={styles.footerSocialIcon}
                src={twitterIllustration}
                alt={t("Footer.twitterAlt")}
              />
            </a>
            <a
              href="https://adapools.org/pool/a343a6e2eb4bb7f8abf039b82e579131b56966d88d9937e679365269"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                className={styles.footerSocialIcon}
                src={adapoolsIllustration}
                alt={t("Footer.adapoolsAlt")}
              />
            </a>
          </div>
          <address className={styles.footerAddress}>
            {t("Footer.address")}
          </address>

          <hr className={styles.footerDivider} />
          <p className={styles.footerCopyright}>
            Copyright &copy; {new Date().getFullYear()}. All rights reserved.
          </p>
          <Flags />
        </div>
      </footer>

      <Modal
        className={styles.modal}
        isOpen={modalIsOpen}
        onCloseClick={toggleModalClick}
      >
        <Title
          textFat={t("Modal.titleLineOne")}
          textThin={t("Modal.titleLineTwo")}
        />
        <p>{t("Modal.stepOne")}</p>
        <p>{t("Modal.stepTwo")}</p>
        <p>{t("Modal.stepThree")}</p>
        <p>{t("Modal.stepFour")}</p>
      </Modal>
    </SWRConfig>
  );
};

export default Home;

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  const stats = await getStatsFromApi();

  return {
    props: {
      messages: (await import(`../i18n/index/${locale}.json`)).default,
      fallback: {
        "/api/stats": stats,
      },
    },
  };
}
