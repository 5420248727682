export type GetLocalizedPathOptions = {
  locale?: string;
  paths: {
    en: string;
    nl?: string;
  };
};

export function getLocalizedPath(options: GetLocalizedPathOptions) {
  const { locale, paths } = options;

  const { en, nl = en } = paths;

  let localePath;
  let localizedPath;

  switch (locale) {
    case "nl":
      localePath = "/nl";
      localizedPath = nl;
      break;
    default:
      // Default to english.
      localePath = "";
      localizedPath = en;
      break;
  }

  return `${localePath}${localizedPath}`;
}
