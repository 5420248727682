import clsx from "clsx";
import Image from "next/future/image";
import type { FC } from "react";
import React, { useState } from "react";

import closeIcon from "../../public/icons/close.svg";

import styles from "./faq-item.module.scss";

export type Props = {
  className?: string;
  question: string;
  answer: string;
};

const FAQItem: FC<Props> = (props): JSX.Element => {
  const { className, question, answer } = props;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <article
      className={clsx(styles.container, className)}
      onClick={() => {
        setIsOpen((s) => !s);
      }}
    >
      <Image
        className={clsx({
          [styles.open]: true,
          [styles.close]: isOpen,
        })}
        src={closeIcon}
        alt="Close icon."
      />
      <h3
        className={clsx({
          [styles.question]: true,
          [styles.questionOpen]: isOpen,
        })}
      >
        {question}
      </h3>
      <p
        className={clsx({
          [styles.answer]: true,
          [styles.answerOpen]: isOpen,
        })}
      >
        {answer}
      </p>
    </article>
  );
};

FAQItem.displayName = "FAQItem";
FAQItem.defaultProps = {};

export default FAQItem;
