import clsx from "clsx";
import type { FC } from "react";
import React, { memo } from "react";

import styles from "./button.module.scss";

export type Props = {
  className?: string;
  text: string;
  onClick: () => void;
  glass?: boolean;
};

const Button: FC<Props> = (props): JSX.Element => {
  const { className, text, onClick, glass } = props;

  return (
    <button
      className={clsx(
        {
          [styles.container]: true,
          [styles.glass]: glass,
        },
        className
      )}
      onClick={onClick}
      type="button"
    >
      {text}
    </button>
  );
};

Button.displayName = "Button";
Button.defaultProps = {
  glass: false,
};

export default memo(Button);
