import clsx from "clsx";
import Image from "next/future/image";
import type { FC, PropsWithChildren } from "react";
import React from "react";

import closeIcon from "../../public/icons/close.svg";

import styles from "./modal.module.scss";

export type Props = PropsWithChildren<{
  className?: string;
  isOpen: boolean;
  onCloseClick: () => void;
}>;

const Modal: FC<Props> = (props): JSX.Element => {
  const { className, isOpen, onCloseClick, children } = props;

  return (
    <aside
      className={clsx({
        [styles.wrapper]: true,
        [styles.wrapperOpen]: isOpen,
      })}
    >
      <div
        className={clsx({
          [styles.container]: true,
          [styles.containerOpen]: isOpen,
        })}
      >
        <button className={styles.close} onClick={onCloseClick}>
          <Image
            className={styles.closeIcon}
            src={closeIcon}
            alt="Close icon."
          />
        </button>
        <div className={clsx(styles.content, className)}>{children}</div>
      </div>
    </aside>
  );
};

Modal.displayName = "Modal";

export default Modal;
