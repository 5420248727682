import clsx from "clsx";
import type { FC } from "react";
import React, { Fragment, memo } from "react";

import styles from "./title.module.scss";

export type Props = {
  className?: string;
  textFat: string;
  textThin: string;
  h1?: boolean;
  monotone?: boolean;
  newLine?: boolean;
};

const Title: FC<Props> = (props): JSX.Element => {
  const { className, textFat, textThin, h1, monotone, newLine } = props;

  const content = (
    <Fragment>
      {textFat}
      {newLine ? <br /> : " "}
      <span
        className={clsx({
          [styles.thin]: true,
          [styles.monotone]: monotone,
        })}
      >
        {textThin}
      </span>
    </Fragment>
  );

  if (h1) {
    return (
      <h1
        className={clsx(
          {
            [styles.base]: true,
            [styles.monotone]: monotone,
          },
          className
        )}
      >
        {content}
      </h1>
    );
  }

  return (
    <h2
      className={clsx(
        {
          [styles.base]: true,
          [styles.monotone]: monotone,
        },
        className
      )}
    >
      {content}
    </h2>
  );
};

Title.displayName = "Title";
Title.defaultProps = {
  h1: false,
  monotone: false,
  newLine: false,
};

export default memo(Title);
