import { useRouter } from "next/router";

import { baseCanonical } from "../config/seo";
import {
  getLocalizedPath,
  GetLocalizedPathOptions,
} from "../utils/get-localized-path";

export type UseCanonicalOptions = {
  paths: GetLocalizedPathOptions["paths"];
};

export type CanonicalData = {
  canonical: string;
};

export function useCanonical(options: UseCanonicalOptions): CanonicalData {
  const { locale } = useRouter();
  const { paths } = options;

  const localizedPath = getLocalizedPath({
    locale,
    paths,
  });

  const canonical = `${baseCanonical}${localizedPath}`;

  return { canonical };
}
