import clsx from "clsx";
import type { FC } from "react";
import React from "react";
import Link from "next/link";
import Image from "next/future/image";

import usFlag from "../../public/icons/flags/us.svg";
import nlFlag from "../../public/icons/flags/nl.svg";

import styles from "./flags.module.scss";
import { useTranslations } from "next-intl";

export type Props = {
  className?: string;
};

const Flags: FC<Props> = (props): JSX.Element => {
  const { className } = props;

  const t = useTranslations("Flags");

  return (
    <div className={clsx(styles.container, className)}>
      <Link href="/" locale="en">
        <Image className={styles.flagIcon} src={usFlag} alt={t("enFlagAlt")} />
      </Link>
      <Link href="/nl" locale="nl">
        <Image className={styles.flagIcon} src={nlFlag} alt={t("nlFlagAlt")} />
      </Link>
    </div>
  );
};

Flags.displayName = "Flags";
Flags.defaultProps = {};

export default Flags;
