import clsx from "clsx";
import { useTranslations } from "next-intl";
import type { FC } from "react";
import React from "react";
import useSWR from "swr";

import styles from "./stats.module.scss";

const fetcher = (url: string) => fetch(url).then((r) => r.json());

export type Props = {
  className?: string;
  epochFeeAmt: number;
  marginFeePct: number;
  returnOfStakePct: number;
};

const Stats: FC<Props> = (props): JSX.Element => {
  const { className, epochFeeAmt, marginFeePct, returnOfStakePct } = props;

  const t = useTranslations("Stats");

  const { data } = useSWR("/api/stats", fetcher);

  const liveStakeAmt = Math.round(data.liveStakeAmt);

  return (
    <div className={clsx(styles.container, className)}>
      <p className={styles.text}>
        <span className={styles.bold}>{t("epochFee")}</span>{" "}
        {t("epochFeeValue", {
          value: epochFeeAmt,
        })}
      </p>
      <p className={styles.text}>
        <span className={styles.bold}>{t("marginFee")}</span>{" "}
        {t("marginFeeValue", {
          value: marginFeePct,
        })}
      </p>
      <p className={styles.text}>
        <span className={styles.bold}>{t("returnOfStake")}</span>{" "}
        {t("returnOfStakeValue", {
          value: returnOfStakePct,
        })}
      </p>
      <p className={styles.text}>
        <span className={styles.bold}>{t("liveStake")}</span>{" "}
        {t("liveStakeValue", {
          value: liveStakeAmt,
        })}
      </p>
    </div>
  );
};

Stats.displayName = "Stats";
Stats.defaultProps = {};

export default Stats;
