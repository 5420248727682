import clsx from "clsx";
import type { FC } from "react";
import React, { memo } from "react";

import styles from "./button-link.module.scss";

export type Props = {
  className?: string;
  text: string;
  href: string;
  glass?: boolean;
};

const ButtonLink: FC<Props> = (props): JSX.Element => {
  const { className, text, href, glass } = props;

  return (
    <a
      className={clsx(
        {
          [styles.container]: true,
          [styles.glass]: glass,
        },
        className
      )}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  );
};

ButtonLink.displayName = "ButtonLink";
ButtonLink.defaultProps = {
  glass: false,
};

export default memo(ButtonLink);
